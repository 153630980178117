module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://content.carib-export.com/graphql","excludeFieldNames":["allNews","career","careers","categories","category","comment","comments","event","events","link","links","menu","menus","menuItem","menuItems","news","opportunity","opportunities","page","pages","post","posts","publication","publications","statistic","statistics","tag","tags","taxonomy","taxonomies","viewer","user","users","userRole","userRoles"],"schema":{"timeout":9000000,"perPage":20,"requestConcurrency":5,"previewRequestConcurrency":2,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"type":{"__all":{"limit":5},"MediaItem":{"createFileNodes":true,"localFile":{"maxFileSizeBytes":2097152,"requestConcurrency":50,"excludeByMimeTypes":["video/mp4","application/pdf"]},"placeholderSizeName":"gatsby-image-placeholder","lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"develop":{"hardCacheMediaFiles":true,"hardCacheData":true,"nodeUpdateInterval":5000},"production":{"allow401Images":true,"allow404Images":true,"hardCacheMediaFiles":true},"html":{"useGatsbyImage":false,"createStaticFiles":false,"fallbackImageMaxWidth":1000,"imageQuality":95,"placeholderType":"blurred","imageMaxWidth":null,"generateWebpImages":true,"generateAvifImages":false},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d67924b658f5c1a0eb9a809bcd5c950e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-1KLBR15L9Y"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gravity-forms/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://content.carib-export.com/graphql"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
